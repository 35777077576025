<template>
  <div class="misc-wrapper">
    <b-link
      to="/"
      class="brand-logo"
    >
      <b-img
        :src="appLogoFull"
        height="40"
      />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('notAuthorized.title') }}
        </h2>
        <p class="mb-2">
          {{ $t('notAuthorized.text') }}
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          :to="loginRoute()"
        >
          {{ $t('notAuthorized.backToHomeButton') }}
        </b-button>
        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

import store from '@/store/index'
import { getHomeRouteForLoggedInUser, getUserData } from '@/api/auth/utils'

import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink, BImg, BButton,
  },
  setup() {
    const { appLogoFull: defaultLogo } = $themeConfig.app
    const appLogoFull = computed(() => store.getters['app/getLogo']).value || defaultLogo
    return {
      appLogoFull,
    }
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    loginRoute() {
      const { employeeData } = getUserData()
      return getHomeRouteForLoggedInUser(employeeData)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
